<template>
  <div class="login" v-cloak>
       <div class="tops">
         <div><</div>
         <div class="topcolor">游客登录</div>
       </div>
       <div class="bottom">
          <div class="title">
            登录/注册
            <div class="titlewirte">未注册的手机号将自动创建账号</div>
          </div>
          <div class="input" v-if='VerificationCode'>
             <van-field class="accountNumber" v-model="tel" type="tel"    placeholder="请输入用户名"/>
             <van-field class="password"  v-model="password" type="password"   placeholder="请输入密码"/>
          </div>
           <div class="input Verification Code" v-else>
            <van-field class="accountNumber" v-model="tel" type="tel"    placeholder="请输入用户名"/>
            <!-- <div> -->
             <van-field class="password"  v-model="password"  type="tel"  placeholder="请输入验证码"/>
             <div class="right" @click='sendout'>  
               <span v-show="show" @click="sendout">获取验证码</span>
               <span v-show="!show" class="count">{{count}} 秒</span>
             </div>
            <!-- </div> -->
            
          </div>
          <div class="btn">
            <div class="left" @click='switchs' v-if='VerificationCode'>验证码登录</div>
            <!-- <div class="left" @click='switchs' v-else>密码登录</div>
            <div class="right" @click='switchs' v-show='VerificationCode'>忘记密码</div> -->
          </div>
          <div class="loginpassword" @click='switchs'>
            密码登录
          </div>
          <div class="logins" @click='details'>
            立即登录
          </div>
          <div class="agreement">
            <img src="../../assets/dlwxxy.png" alt="" v-if='img' @click='imgs'>
             <img src="../../assets/dlxdxy.png" alt="" v-else @click='imgs'>
            <span>我已阅读并同意<a style="color:blue">《用户协议》</a>和<a style="color:blue">《隐私协议》</a></span> 
          </div>
       </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie';
import { Toast } from 'vant';
import request from "../../plugin/request";
export default {
    data() {
    return {
      VerificationCode:false,
      password:'',
      tel:'',
      text:'获取验证码' ,
      img:false,
      show: true,
      count: '',
      timer: null,
      url:'',
      id:'',
      group_id:'',
      course_id:'',
      share_id:''
    };
  },
    mounted(){
    let currentQuery = this.$route.query;
    this.url=currentQuery.url
    this.id=currentQuery.id;
    this.group_id=currentQuery.group_id
    this.course_id=currentQuery.course_id
    this.share_id=currentQuery.share_id
    this.$nextTick(() => {
    });
  },
  methods:{
  getCode(){
 const TIME_COUNT = 60;
 if (!this.timer) {
 this.count = TIME_COUNT;
 this.show = false;
 this.timer = setInterval(() => {
 if (this.count > 0 && this.count <= TIME_COUNT) {
  this.count--;
  } else {
  this.show = true;
  clearInterval(this.timer);
  this.timer = null;
  }
 }, 1000)
 }
} ,
setToken(token){
       return Cookies.set('Token',  token)
          },
    imgs() {
       this.img=!this.img
    },
    switchs() {
      this.VerificationCode=!this.VerificationCode
      this.password=''
    },
    async sendout() {
      if(!(/^1[3456789]\d{9}$/.test(this.tel))){ 
        Toast('请输入正确手机号码');  
        return
     } else {

      this.getCode()
      const data = await request.post("/login/send", {
         event:'login',
         mobile:this.tel,
       });    
      console.log(data)
     }
     
    },
  // 登录
  async details() {
     if(!(/^1[3456789]\d{9}$/.test(this.tel))){ 
         Toast('请输入正确手机号码');  
     }  else {
       if(this.password==''){
         Toast("请输入密码或验证码！");
       }else {
            if (this.VerificationCode)  {
      var parameter = {
        method:'loginPassword',
        device:'h5',
        mobile:this.tel,
        password:this.password 
       }
      } else {
       var parameter = {
        method:'loginVerificationCode',
        device:'h5',
        mobile:this.tel,
        verificationCode:this.password
       }
      }
      if(this.img==false) {
        const data = await request.post("/login",parameter);    
        console.log(data)
        if(data.code!=200) {
           Toast(data.message)
        } else {
           console.log(data.data.token)
           localStorage.setItem('token', data.data.token);
           if(this.url=='GroupWork') {
              this.$router.push({path:'/GroupWork?id='+this.id+'&course_id='+this.course_id+'&group_id='+this.group_id+'&&share_id='+this.share_id})
           } else {
              this.$router.push({path:'/'+this.url+'?id='+this.id+'&&share_id='+this.share_id})
           }
        }   
      } else {
       Toast('请先勾选用户协议')
       }
      } 
     }
    },
  }
};
</script>
<style lang="less" scoped >
.login {
  .tops {
    // width: 100%;
    // height: 1.2rem;
    // background-color: #2371FF;
    // border-bottom-right-radius:.70rem;
    // border-bottom-left-radius:.70rem;
    // position: fixed;
    // top:0;
    // left: 0;
    // margin:0
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
    font-size: 0.5rem;
    margin-top: 0.2rem;
    .topcolor{
     color: #2371FF;
     font-size: 0.4rem;
    }
  }

  .bottom {
    // background: url(../../assets/dlbj.png);
    width:100%;
    height: 8.03rem;
    background-size: contain;
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    top:.70rem;
    .title  {
      font-size: .6rem;
      color: #2371FF;
      font-weight: 400;
      width: 100%;
      // text-align: center;
      margin-top:.4rem;
      padding-left: 0.8rem ;
      padding-right: 0.3rem ;
      .titlewirte{
        font-size: 0.2rem;
        color: #767676;
        margin-top: 0.4rem;
      }
    }
    .input  {
     .van-cell,.van-field {
         width: 7.88rem;
         height: 1.12rem;
        //  background: url(../../assets/dlinput.png);
         background-size: 100% 100%;
         margin-top:.39rem;
         margin-left: .73rem;
        //  background-color: #ebeff2;
      }
      .accountNumber .van-cell__title {
         width: .48rem;
         height: .57rem;
         background: url(../../assets/dlzh.png);
         background-size: 100% 100%;
         span {
           display: none;
          }
       }
       .password  .van-cell__title {
         width: .44rem;
         height: .57rem;
         background: url(../../assets/dlmm.png);
         background-size: 100% 100%; 
         span {
           display: none;
          }
       }
    }
    .Verification {
      overflow: hidden;
     .van-cell,.van-field {
         width: 7.88rem;
         height: 1.12rem;
        //  background: url(../../assets/dlzhsr.png);
         background-size: 100% 100%;
         margin-top:.8rem;
         margin-left: .73rem;
        //  background-color: #ebeff2;
      }
      .accountNumber .van-cell__title {
         width: .48rem;
         height: .57rem;
         background: url(../../assets/dlzh.png);
         background-size: 100% 100%;
         span {
           display: none;
          }
       }
       .password  {
         width: 4.8rem;
        //  background: url(../../assets/dlyzmk.png);
         background-size: 100% 100%;
         float: left;
       }
       .right {
         float: left;
         width: 3.03rem;
         height: 1.19rem;
        //  background: url(../../assets/dlyzmfs.png);
         background-size: 100% 100%;
         margin-top:.8rem;
         margin-left: .12rem;
         text-align: center;
         line-height: 1.19rem;
         color: #2371FF;
         font-size: .4rem;
       }
       .password  .van-cell__title {
         width: .44rem;
         height: .57rem;
         background: url(../../assets/dlmm.png);
         background-size: 100% 100%; 
         span {
           display: none;
          }
       }
    }
    .btn {
      width: 100%;
      height: .8rem;
      line-height: .8rem;
      margin-top:.11rem;
      .left {
        width: 2.01rem;
        height: .8rem;
        background: url(../../assets/dlyzm.png);
        background-size: 100% 100%;
        text-align: center;
        line-height:  .8rem;
        font-size: .29rem;
        color: #B4BCCE;
        margin-left: .71rem;
        float: left;
        
      }
    .right {
        float: right;
        margin-right: 1.05rem;
        font-size: .29rem;
        color: #B4BCCE;
      }
    }
    .loginpassword{
       width: 3rem;
      height: 1rem;
      // background: url(../../assets/dlbtn.png);
      background-size: 100% 100%;
      text-align: center;
      line-height: 1rem ;
      font-size:.35rem ;
      color: #B5B5B5;
      margin-left:3.2rem;
      
    }
    .logins {
      width: 3rem;
      height: 1rem;
      // background: url(../../assets/dlbtn.png);
      background-size: 100% 100%;
      text-align: center;
      line-height: 1rem ;
      font-size:.35rem ;
      color: #fff;
      border: 1px solid #4081FF;
      border-radius: 0.2rem;
      background-color: #4081FF;
      margin-left:3.2rem;
      margin-top:.19rem
    }
    .agreement {
      width: 100%;
      margin-bottom:.35rem;
      text-align: center;
      font-size: .29rem;
      position: fixed;
      bottom: 0;
      img {
            vertical-align: middle;
            width: .39rem;
            height: .39rem;
      }
      span {
         display: inline-block;
         margin-left:.09rem;
          color: #596277;
          line-height:.39rem;
      }
    }
  }
}
</style>